.NavbarItems {
  /* display: flex; */
  justify-content: space-evenly;
  align-items: center;
  padding: 0 30px;
  box-shadow: 0 5px 15px 0 rgba(0, 0, 0, 0.25);
  width: 100%;
  height: 80px;
  position: fixed;
  left: 50%;
  top: 0;
  transform: translate(-50%);
  z-index: 99999;
  background-color:  #ffffff;
}

.NavbarItems {
  transition: background-color 0.3s ease-in-out;
}

.NavbarItems {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 30px;
  box-shadow: 0 5px 15px 0 rgba(0, 0, 0, 0); /* initially set to no box shadow */
  transition: box-shadow 0.3s ease; /* transition for box-shadow property */
  width: 100%;
  height: 80px;
  position: fixed;
  left: 50%;
  top: 0;
  transform: translate(-50%);
  z-index: 99999;
  background-color:  #ffffff;
}

.NavbarItems.scrolled {
  box-shadow: 0 5px 15px 0 rgba(0, 0, 0, 0.25); /* box shadow when scrolled */
}

/* Rest of your CSS... */


.navbar-logo {
  color: #222;
  font-size: 2rem;
  font-weight: 700;
  cursor: pointer;
  display: flex;
  justify-content: center;
}
.navbar-logo:hover{
  color:#4424af;
}
.navbar-logo:visited{
  color: #0e1554;
}
.navbar-logo img{
  width: 50px;
  height: 50px;
  padding: 5px;
}
.navbar-logo h1{
  /* width: 0%; */
  /* height: 80px; */
  font-size: 30px;
  color: #0e1554;
}
.nav-menu {
  display: grid;
  grid-template-columns: repeat(7, auto);
  grid-gap: 10px;
  list-style: none;
  align-items: center;
}

.nav-links {
  text-decoration: none;
  color: #222;
  font-size: 1.2rem;
  font-weight: 700;
  padding: 0 10px;
  white-space: nowrap;
  position: relative;
}

.nav-links::after{
  content: "";
  position: absolute;
  background-color: #100fb1;
  height: 3px;
  width: 0;
  left: 0;
  bottom: -8px;
  transition: 0.3s;
}
.nav-links:hover::after{
  width: 100%;
}
.nav-links.active::after{
  width: 100%;
}
.nav-links i {
  padding-right: 10px;
}


.btn{
  background-color: #4424af;
  padding: 10px 20px;
  color: white;
  font-size: 20px;
  border-radius: 40px;

}
.btn:hover{
  background-color: #0e1554;
}

  
.nav-links:hover {
  /* background-color: #9ca2a9;
  color: #fff;
  border-radius: 4px;
  transition: all 0.2s ease-in-out; */
}

.menu-icons{
    display: none;
}

/* .active{
  background-color: #100fb1;
  color: black;
  border-radius: 4px;
  transition: all 0.2s ease-in-out;
} */
.contact-nav {
  display: none; /* Initially hide the contact-nav div */
}

@media screen and (max-width: 800px) {
  
  .navbar-logo h1{
    display: flex;
    font-size: 25px;
    align-items: center;
    margin: 0 auto;
    color: #0e1554;
  }
}

@media screen and (max-width: 1023px) {
  .navbar-logo{
    font-size: 1.7rem;
  }
  .NavbarItems {
    z-index: 99;
    position: fixed; /* Add this line */
    width: 100%; /* Add this line */
    z-index: 1000;

  }

  .menu-icons {
    display: block;
    padding: 20px;
    margin-top: 10px;
  }
  .menu-icons i{
    font-size: 1.8rem;
  }

  .nav-menu {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;
    height: auto;
    position: absolute;
    top: 80px;
    left: -100%;
    opacity: 0;
    align-items: center;
    padding: 30px 0;
    margin: 0;
    background-color: rgb(255, 255, 255);
    transition: all 0.5s ease;
    cursor: pointer;
    
  }

  .nav-menu.active {
    left: 0;
    opacity: 1;
    z-index: 1;
  }

  .nav-links {
    display: block;
    width: 100%;
    font-size: 1.2rem;
    padding: 1rem 0;
    white-space: nowrap;
    font-weight: 700;
    position: relative;
    font-weight: 600;

    
  }
  /* .nav-links:hover {
    background-color: #0e1554;
    align-items: center;
    justify-content: center;
    height: auto;
    padding: 10px 40px;
    transition: none;
  } */
  .nav-links::after{
    content: "";
    position: absolute;
    background-color: #100fb1;
    height: 3px;
    width: 0;
    left: 0;
    transition: 0.3s;
  }
  .nav-links:hover::after{
    width: 100%;
  }
  
.nav-links.active::after {
  width: 100%;
}
 

  .contact-nav {
    display: block;
    margin-bottom: 40%;
    margin-top: 20px;
    
  }

  .contact-nav ul{
    
    font-size: 1.5rem;
    padding: 20px 0 0 0;
  } 

  
}

/* Other CSS rules */

/* Add this CSS rule for the active link */

/* Example for smooth transition */
.nav-menu li {
  transition: background-color 0.5s ease;
}

/* HERO SECTION  */
.herosection{
  position: relative;
  font-family: "Montserrat", sans-serif;
  
}


.containers {
  position:relative;
  display: flex; 
  justify-content: space-between;
  height: 100vh;

}

@media screen and (max-width: 1924px) {
  .containers{
    height: 100vh;
  }
}

.main1{
  /* border: 1px solid black; */
  width: 50%;
  padding: 0 40px;
  padding-top: 14%;
  margin-left: 60px;
  
}

.main1 h1{
  font-size: 3rem;
  font-weight: 700;
  /* margin-left: 20px;  */
  width: 80%;
}

.main1 p{
  font-size: 1rem;
  font-weight: 500;
  margin-top: 05px;
  /* margin-left: 20px; */
  width: 80%;
  margin-bottom: 30px;
  
  
}

.main2{
  padding: 0 40px;
  padding-top: 7%;
  /* border: 1px solid black; */
  width: 50%;
}
/* Heading styles */






/*clients*/
.clients_page {
  background-color: white;
  height: auto;
  padding:20px;
  margin-top:100px;
}

.clients_page h1{
  font-family: "Montserrat", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-weight: 700;
  /* color: #100fb1; */
}

.heading {
  color: black;
  text-align: center;
  font-size: 40px;
  font-weight: 700;
  padding: 1em 0;
}

.clients {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  padding: 30px;
  gap: 20px;
}

.client {
  border-radius: 10px;
  padding: 20px;
  text-align: center;
  width: 230px;
  transition: transform 0.2s;
}

.client img {
  max-width: 100%;
  height: 150px;
  border-radius: 5px;
  transition: transform 0.2s;
}

.client:hover {
  transform: scale(1.05);
}

hr {
  display: none; 
  border: none;
  height: 1px; 
  background-color: black; 
}

/* Responsive Design */
@media (max-width: 1024px) {
  .clients_page {
    padding-left: 10%;
    padding-right: 10%;
  }

  .heading {
    font-size: 35px;

  }

  .client {
    width: 45%;
  }
  .client img {
    max-width: 90%;
    width: 80%;
    margin-left: 18%;
    height: 180px;

    
  }
}

@media (max-width: 768px) {
  .clients_page {
    padding-left: 8%;
    padding-right: 8%;
  }

  .heading {
    font-size: 30px;
  }
  

  .client {
    width: 48%;
  }

  .clients {
    padding: 20px;
    gap: 15px;
  }
  .client img {
    max-width: 90%;
    width: 100%;
    height: 180px;
    /* margin-left: 18%; */
    
  }
}

@media (max-width: 600px) {
  .clients_page {
    padding-left: 5%;
    padding-right: 5%;
  }

  .heading {
    font-size: 28px;
  }

  .client {
    width: 100%;
    margin: 10px 0;
  }

  .client img {
    max-width: 70%;
    width: 100%;
    margin-left: 18%;

  }
  hr {
    display: block;
    width: 80%;  /* Set width of HR */
    margin: 0 auto; /* Center horizontally */
  }
}

@media (max-width: 480px) {
  .clients_page {
    padding: 0 2%;
  }

  .heading {
    font-size: 30px;
  }

  .clients {
    flex-direction: column;
    align-items: center;
    width: 100%;
    margin: 0 auto;
    padding: 10px;
  }

  .client {
    width: 100%;
    margin: 10px 0;
    padding: 15px;
  }

  .client img {
    max-width: 70%;
    width: 100%;
    margin-left: 18%;
    height: 100%;
  }
}

@media (max-width: 425px) {
  .clients_page {
    padding: 0 2%;
  }

  .heading {
    font-size: 22px;
  }

  .clients {
    flex-direction: column;
    align-items: center;
    width: 100%;
    margin: 0 auto;
    padding: 10px;
  }

  .client {
    width: 100%;
    margin: 10px 0;
    padding: 15px;
  }

  .client img {
    max-width: 70%;
    width: 100%;
    margin-left: 15%;
  }
}

@media (max-width: 375px) {
  .heading {
    font-size: 20px;
  }

  .client p {
    font-size: 12px;
  }

  .client img {
    max-width: 70%;
    width: 100%;
    margin-left: 15%;
  }
}

@media (max-width: 320px) {
  .heading {
    font-size: 18px;
  }

  .client p {
    font-size: 10px;
  }

  .client img {
    max-width: 70%;
    width: 100%;
    margin-left: 15%;
  }
}


/* @media (max-width: 768px) {
  .client {
      width: 150px;
      padding: 20px;
  }
  
}


@media (max-width: 480px) {
  .clients {
      flex-direction: column;
      align-items: center;
      width: 80%;
      margin-left: 10%;

  }
  .client {
      width: 100%;
      margin: 10px 0;
  }
}



 @media (min-width: 1024px) {
  .clients_container{ 
    margin-left: 30%;
  }
  .clients_container h1{
      margin-left: -50px;
      font-size: 40px;
      font-weight: 700;
  }
  
}
@media (min-width: 1280px) {
  .clients_container{ 
    margin-left: 5%;
  }
  .clients_container h1{
      margin-left: -50px;
  }
  
} */
/*
@media (max-width: 992px) {
  .client {
    width: 180px;
  }
}

@media (max-width: 768px) {
  .client {
    width: 150px;
  }
}

  @media (max-width: 576px) {
    .clients {
      flex-direction: column;
      align-items: center;
    }

    .client {
      width: 80%;
      margin: 10px 0;
    }
  } */







/* Button styles */
.button {
    margin: 5px 10px;
    display: inline-block;
    padding: 15px 30px;
    background-color: #100fb1; /* Green */
    border: none;
    color: white;
    cursor: pointer;
    font-size: 1.1rem;
    position: relative;
    overflow: hidden;
    transition: transform 0.3s ease;
    border-radius: 40px;
}

.button::before {
  content: '';
  position: absolute;
  background-color: rgba(255, 255, 255, 0.3);
  width: 200%;
  height: 200%;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%) rotate(45deg);
  transition: width 0.3s ease, height 0.3s ease;
  z-index: -1;
}

.button:hover::before {
  width: 0;
  height: 0;
}

.button:hover {
  transform: translateY(-5px); 
  background-color: #9ca2a9;/* Move the button up slightly on hover */
  /* color: #100fb1; */
  /* color: #000; */
}

/* Additional styles for side animation */
.button:hover::after {
  content: '';
  position: absolute;
  width: 10px;
  height: 10px;
  background-color: #fff;
  top: 50%;
  left: -20px;
  border-radius: 50%;
  animation: sideAnimation 1s infinite alternate;
}

/* slider  */

.max-width{
  max-width: 20rem;
}


@keyframes sideAnimation {
  0% {
    left: -20px;
  }
  100% {
    left: calc(100% + 20px);
  }
}

@media only screen and (max-width: 1440px){
  .herosection{
    height: fit-content;
  }
  .containers{
    height: 100vh;
    min-height: auto;
    margin: 0;
  }

  .main1{
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
    padding: 3rem 1rem;
    margin-top:7%;
    overflow: hidden;
  }
  .main1 h1{
    font-size: 2.5rem;
  }
  
}
@media only screen and (max-width: 1024px){
    .containers{
      height: fit-content;
    }
    .main1{
      /* border: 1px solid black; */
      padding: 0 !important;
      padding-top: 10% !important;
    }
}

@media only screen and (max-width: 600px){
  .main2{
    width: 100% !important;
    margin-left: 0 !important ;
    margin-bottom: 20% !important;

  }
  .main1{
    margin-top: 10% !important ;
    
  }
}


@media screen and (max-width: 768px) {
  .containers{
    display: flex;
    justify-content: center;
    position: relative;
    margin-top:8vh;
    flex-direction: column;
  } 
  .main1{
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: left;
    justify-content: center;
    width: 100%;
    margin: auto; 
    overflow-x: hidden;
  }
  .main2{
    margin-left: 25%;
    width: 55%;
    /* border: 1px solid black; */

    
  }

  
  
  .containers h1{
    font-size: 2rem;
    /* border: 1px solid black; */
    text-align: center;
    width: 100%;
    /* border: 1px solid black; */
  }
  .containers p{
    padding:  10px;
    line-height: 1.6em;
    max-width: 90%;
    text-align: center;
  }
}





.fixed{
  position: absolute;
  margin-top: 40px ;

}

/* CARD SECTION */

.linkservice {
  margin-top: 50px;
  margin-bottom: -20px;
}



/* project */

.hover\:scale-105:hover {
  transform: scale(1.05);
}


/* service */

.service-main{
  position: relative;
  margin-top: 5%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #fff;
  height: auto;

}

.servicegrid{
  display:inline-block;
  align-items: center;
  justify-content: center;

}

.servicegrid h1{
  display: flex;
  justify-content: center;
  text-align: center;
  font-size: 2.5rem;
  font-weight: 700;
  font-family: "Montserrat", sans-serif;
  scroll-behavior: smooth;
}

.servicegrid p{
  /* border: 1px solid black;   */
  padding: 0 40px;
  width: 600px;
  text-align: center;
  font-size: 1.2rem;
  font-weight: 400;
} 
/* .service-main img {
   width: 80%;
  /* height: 50%; */
  /* object-fit: cover;
  border-radius: 0.5rem; */
/* } */ 

.service-main img{
  width: 500px;
  height: 250px;
}





/* Container for the entire section */
.margin{
  margin-top:50px;
}
.web-container{
  display: flex;
  width: 100%;
  background-color: ghostwhite;
  height: fit-content;
  justify-content: center;
  align-items: center;

}

.web{
  display: inline-block;
  width: 40%;
  padding: 40px;
}

.web h1{
  font-size: 2.6rem;
  font-weight: 700;
}
.web p{
  font-size: 1.2rem;
  font-weight: 400;
  padding: 5px 0px;
}

.whitbg{
  background-color: white;
}

.list-icon{
  margin-top: 20px;

}



.item{
  /* border: 1px solid black; */
  margin: 5px 10px;
  padding: 1px 10px;
  text-align: left;
  font-size: 20px;
}
.fa-solid{
  font-size: 1.4rem;
}
.fa-solid:hover{
  color: #9ca2a9;
}

.mobile{
  background-color: white;

}

.idea{
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 40px;
}

.idea-bulb{
  border: 2px solid black;
  display: flex;
  padding: 40px;
  border-left: none;
  border-right: none;
}

.bulb{
  width: 100px;

  
}

.bulb i{
  font-size: 80px;
}

.content{

}

.content h1{
  font-size: 2rem;
  font-weight: 700;

}

.content p{
  font-size: 1rem;
  margin: 10px;
}
.talklink{
  margin-top: 40px;
  border: 2px solid black;
  border-radius: 40px;
  font-size: 18px;
  color: black;
  padding: 5px 10px ;
}
.talklink:hover{
  background-color: #9ca2a9;
  color: white;
}

.uiix-img{
  /* height={300} width={'100%'}, */
  object-fit: cover;
  width: auto;
  height: 300px;  
}



/* CSS for responsive design */
.about-heading{
  font-size: 2.5rem;
  font-family: "Montserrat", sans-serif;
  /* color: #007bff; */
  font-weight: 700;
  /* font-style: normal; */
}

.service-main h2{
  font-size: 700;
}

/* For mobile devices */
@media only screen and (max-width: 850px) {
    .service-main{
      /* border: 1px solid black; */
      position: relative;
      height: auto;
      margin-top: 30% !important;
      
    }
    .service-main h2{
      font-size: 700;

    }
    .servicegrid{
      /* border: 1px solid black; */
      width:  100%;
    }

    .uiix-img{
      margin: 0 auto;
      height: 100%;
      padding:0 0px;
      object-fit: cover !important;
      overflow:hidden !important;
    }
    .lottie-animation{
      border: 1px solid red;
    }

    .service-button{
      padding: 15px 15px;
    }
    .container h1{
      font-size: 2rem;
      font-weight: 800;
      font-style: normal;

    }
    .about-heading{
      font-size: 2.5rem;
      font-family: "Montserrat", sans-serif;
      /* color: #007bff; */
      font-weight: 700;
      /* font-style: normal; */
    }


    .servicegrid h1{
      font-size: 2.5rem;
      font-family: "Montserrat", sans-serif;
      scroll-behavior: smooth;
      font-weight: 800;
      font-style: normal;
    }
    .servicegrid p{
      width: 100% !important;
      text-align: center !important;

    }
    .idea-bulb{
      width: 100%;
      display: flex;
      height: fit-content;
    }
    .bulb i{
        font-size: 3rem;
    }
    .content h1{
      font-size: 1rem;
      align-items: center;
      margin: 9px;
    }
    .content p{
      font-size: 1rem;
      width: 100%;
      align-items: center;
    }
    .talklink{
      margin-left:  40px;
      margin-top: 20px;

    }
    
    .web-container{
      width: 100%;
      display: grid;
      height: inherit;
      justify-content: space-around;
      margin-bottom: 20px;

    }

    .web{
      width:100%;
      display: grid;
    }

    .web h1{
      font-size: 2rem;

      font-weight: 700;
    }

    /* contact  form */
    .margin-contact{
      margin-top: 40%;
      padding-top: 20%;
    }  
    

    .Toastify__toast {
      margin-left: 10%; /* Add left margin */
      margin-right: 10%; /* Add right margin */
    }
}
.address-link{
  color: blue;
  text-decoration: underline;
}
.text-red {
  color: #9ca2a9;
}

@media only screen and (max-width: 325px) {
  .servicegrid{
    padding: 10px 10px;
  }
  .web-container{
    flex-direction: column;
    word-wrap: break-word;
    justify-content: center;
    align-items: center;
    
  }
  .list-icon{
    margin-left: -40px;
  }

  .idea-bulb{
    font-size:  8vw;
    margin: 0 ;
    padding-top: 30px !important;
    padding: 0;
  }
  
}

.idea-bulb .bulb i.fa-lightbulb {
  color: #000; /* Default color */
}

.idea-bulb:hover .bulb i.fa-lightbulb {
  color: #FFD43B; /* Color on hover */
}


/* project */


.imgproject{
  width: 400px;
  height: auto;
}


.borders{
  /* border: 1px solid black; */
  margin-right: 40px;
  padding-right: 30px;
  align-items: center;
  letter-spacing: 1px;
  font-weight: 400;
}
.borders h1{
  font-weight: 700;
  padding-bottom: 10px;
}
.borders p{
  padding-right: 20px;
}

/* CSS for responsive design */

/* For tablet devices */
@media only screen and (min-width: 600px) and (max-width: 1024px) {
  .service-main {
      position: relative;
      height: auto;
      margin-top: 10%; /* Adjusted margin for tablets */
  }

  .servicegrid {
      width: 100%; /* Ensure grid takes full width */
      padding: 15px; /* Added padding for better spacing */
  }

  .uiix-img {
      display: block;
      width: 70%;
      height: auto;
      margin: auto;
  }

  .servicegrid h1 {
      font-size: 2rem; /* Adjusted font size */
      font-weight: 700; /* Ensured consistent styling */
  }

  .servicegrid p {
      width: 90%; /* Adjusted width for better fit */
      text-align: center;
      padding: 0 20px; /* Added padding for better spacing */
  }

  .idea-bulb {
      width: 100%;
      display: flex;
      height: 300px; /* Adjusted height for tablets */
      padding: 20px; /* Added padding for better spacing */
  }

  .bulb i {
      font-size: 3rem; /* Adjusted icon size */
  }

  .content h1 {
      font-size: 1.5rem; /* Adjusted font size */
      margin: 10px;
  }

  .content p {
      font-size: 1rem;
      width: 100%;
  }

  .talklink {
      margin-left: 20px;
      margin-top: 20px;
      padding: 5px 15px; /* Adjusted padding for better spacing */
  }

  .web-container {
      width: 100%;
      display: flex;
      flex-direction: column; 
      height: auto;
      justify-content: center;
      align-items: center;
      padding: 15px; 
  }

  .web {
      width: 90%;
      display: flex;
      flex-direction: column; /* Stack elements vertically */
      padding: 15px; /* Adjusted padding for better spacing */
  }

  .web h1 {
      font-size: 2.2rem; /* Adjusted font size */
      font-weight: 700; /* Ensured consistent styling */
  }

  .web p {
      font-size: 1.2rem;
      padding: 10px 0px; /* Added padding for spacing */
  }

  .list-icon {
      margin-top: 10px; /* Reduced margin for better spacing */
  }

  .item {
      font-size: 1.2rem; /* Adjusted font size */
      margin: 5px 5px; /* Adjusted margin for better spacing */
      padding: 2px 10px; /* Adjusted padding for better spacing */
  }

  .fa-solid {
      font-size: 1.6rem; /* Adjusted icon size */
  }

  .fa-solid:hover {
      color: #9ca2a9;
  }

  .mobile {
      background-color: white;
  }

  .idea {
      display: flex;
      justify-content: center;
      align-items: center;
      margin: 20px; /* Adjusted margin for better spacing */
  }

  .idea-bulb {
      border: 2px solid black;
      display: flex;
      padding: 20px; /* Adjusted padding */
  }

  .bulb {
      width: 80px;
  }

  .bulb i {
      font-size: 3.5rem; /* Adjusted icon size */
  }

  .content h1 {
      font-size: 1.8rem;
      font-weight: 700;
  }

  .content p {
      font-size: 1rem;
      margin: 10px;
  }

  .talklink {
      margin-top: 20px;
      border: 2px solid black;
      border-radius: 20px;
      font-size: 18px;
      color: black;
      padding: 5px 10px;
  }

  .talklink:hover {
      background-color: #9ca2a9;
      color: white;
  }
}


/* ///////////////////////////////////////----CONTACT PAGE----/////////////////////////////////////////// */


.contactform{
  width: 100%;
  margin-right: 40px;
  height: auto;
}

.margin-contact{
  margin-top: 12% !important;
  margin: 10%;
}


.time-box{
  position: relative;
}

.form-section{
  position: relative;
  margin-top: 15%;
}

.clock-icon{
  font-size: 5rem;
}

.card-contact{
  display: grid;
}

.contact-grid1, .contact-grid2{
  width: 100%;
  margin: auto;
}


/* /////////////////////////////////////////------MAP-----///////////////////////////////////// */

.responsive-map {
  overflow: hidden;
  object-fit:contain;
  padding-top: 40px;
  height: 100vh;
  position: relative;
  width: 80%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 auto; 
  margin-bottom: -10rem;
}

.responsive-map iframe {
  border-radius: 20px;
  position: absolute;
  top: 30px;
  left: 0;
  right: 0;
  width: 100%;
  border: 0; /* Remove border for better appearance */
}

@media (max-width: 768px) {
  .responsive-map {
    padding-bottom: 100%; /* 4:3 aspect ratio for smaller screens */
    width: 90%;
    margin-left: 5%;
    height: 100%;
  }
  .product-Onzup{
    margin-top: -1rem;
  }

  .sbm-container{
    width: 100%;
    display: flex;
    justify-content: center; 
    align-items: center; 
   
  }

  .sbm-container img{
    width: 300px;
    border-radius: 30px;
    box-shadow: 10px 10px 20px rgba(0, 0, 0, 0.3) !important;
  }

  .item-container{
    width: 100%;
    margin: 0;
    font-size: 15px;
  }
  .youtube-video{
    width: 100%;
  }
  .youtube-iframe{
    width: 95%;
    height: 300px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .product-desc{
    margin-top: 1rem;
  }
  

}


/* -------------------------------------------Slider-page----------------------------------------------------------- */





.container-slider {
  max-width: 1600px;
  margin: 0 auto;
  padding: 0 40px; 
  margin-right: 3%;
}

.bord {
  margin-left: 3.7rem;
}

@media screen and (min-width: 1400px) {
  .container-slider {
    margin-left: 10px;
  }
}

@media screen and (max-width: 425px) {
  .container-slider {
    padding-left: 4%;
    padding-right: 4%;
  }
  .project-card {
    padding: 0 30px;
  }
  .img-about{
    height: auto;
    overflow-x: hidden;
    width: 100% !important ;
    max-width: 100%;

  }
}

@media screen and (max-width: 320px) {
  .container-slider {
    padding-left: 0;
  }
  .project-card {
    padding: 0;
  }
}

@media screen and (max-width: 768px),
  (max-width: 1024px) {
  .bord {
    margin: 0;
  }
  .container-slider {
    padding-left: 40px;
  }
  .project-card {
    padding: 0 30px;
  }
  .slider-gap{
   
  }


  .img-about{
    overflow: hidden;
    height: auto;
    width: max-content;

  }
}


.team-one{
  margin: 0 20%;
}

@media screen and (max-width : 600px) {
  .team-one{
    margin: 0 10%;
  }

  
}





/* product */

.youtube-video {
  display: flex;
  justify-content: center;
  align-items: center;
}

.product-container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  height: fit-content;
  margin: 5%;
  box-sizing: border-box;
}

.product-card {
  max-width: 350px;
  margin: 2%;
  width: 100%;
  padding: 20px;
  background: #ffffff;
  border-radius: 12px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  text-align: center;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.product-card:hover {
  transform: translateY(-10px);
  box-shadow: 0 6px 16px rgba(0, 0, 0, 0.2);
} 

.image-container {
  display: flex;
  justify-content: center;
  margin-bottom: 15px;
}

.image-container img {
  max-width: 100%;
  height: 180px;
  object-fit: contain;
  border-radius: 8px;
}

.product-card h3 {
  font-size: 23.5px;
  color: #333333;
  /* margin-bottom: 10px; */
  /* font-weight: bold; */
  font-weight:800;

}

.product-card p {
  font-size: 14px;
  color: #000;
  line-height: 1.6;
  width: 100%; 
  padding: 0 10px; 
  margin: 0 auto; 
  margin-bottom: 20px;
  text-align: justify; 
  margin-top: 5%;
}


.read-more {
  display: inline-block;
  padding: 10px 20px;
  font-size: 14px;
  font-weight: bold;
  color: #ffffff;
  background-color: #ff3c3c;
  text-decoration: none;
  border-radius: 8px;
  transition: background-color 0.3s ease;
}


.read-more:hover {
  background-color: #e03535;
}

.sbm-img{
  border-radius: 30px;
  box-shadow: 10px 10px 20px rgba(0, 0, 0, 0.3);
}

@media (max-width: 768px) {
  
.product-container{
  width: 100% !important;  /* Or any max-width */
  margin: 0 auto; /* Centers the outer div */
  padding: 10px;

  }
  .product-card {
  width: 100% !important; 
  padding: 20px;
  color: white;
  }

  

  .product-card h3 {
    font-size: 20px;
  }

  .product-card p {
    font-size: 16px;
  }

  .image-container img {
    height: 150px;
  }
}

@media (max-width: 480px) {
  .product-card {
    overflow-y: hidden;
    padding: 10px;
  }

  .product-card h3 {
    font-size: 20px;
  }

  .product-card p {
    font-size: 16px;
  }

  .image-container img {
    height: 120px;
  }
}



/* product */

.product-main{
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 5%;
  padding: 2% 0;
  /* background-color: #fff; */
  /* background-color: #f1f1f1; */
  font-family: "Montserrat", sans-serif;
  height: auto;
}


.productgrid{
  display:inline-block;
  align-items: center;
  justify-content: center;

}

.productgrid h1{
  display: flex;
  justify-content: center;
  text-align: center;
  font-size: 2.5rem;
  font-weight: 700;
}

.productgrid p{
  /* border: 1px solid black;   */
  padding: 0 40px;
  width: 600px;
  text-align: center;
  font-size: 1.2rem;
  font-weight: 400;
} 
/* .product-main img {
   width: 80%;
  /* height: 50%; */
  /* object-fit: cover;
  border-radius: 0.5rem; */
/* } */ 

.product-main img{
  width: 500px;
  height: 250px;
}



/* product details */

/* General Container Styles */
.feature-container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 2rem;
  text-align: center;
}

.feature-title {
  font-size: 2.5rem;
  margin-bottom: 2rem;
  font-weight: bold;
  color: #000000;
}

/* Grid Styles */
.feature-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  gap: 2rem;
}

/* Card Styles */
.feature-card {
  background: #fff;
  border: 1px solid #e0e0e0;
  border-radius: 8px;
  padding: 1.5rem;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  text-align: center;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.feature-card:hover {
  transform: translateY(-5px);
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.15);
}

/* Icon Styles */
.feature-icon {
  font-size: 3rem;
  color: #007bff;
  margin-bottom: 1rem;
}

/* Heading Styles */
.feature-heading {
  font-size: 1.25rem;
  margin-bottom: 0.5rem;
  font-weight: 600;
  color: #333;
}

/* Description Styles */
.feature-description {
  font-size: 0.95rem;
  color: #666;
  line-height: 1.5;
}

/* Responsive Design */
@media (max-width: 768px) {
  .feature-title {
    font-size: 1.5rem;
  }

  .feature-grid {
    grid-template-columns: 1fr;
    gap: 1.5rem;
  }

  .product-margin{
    padding: 30px ;
    margin-top: 15%;
  }
  .productgrid p{
    width: 100%;
  }
  
}
